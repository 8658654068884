import { useEffect } from 'react'
import Layout from '../components/Layout'
import { logPageView } from '../lib/analytics'

export default function Four0Four() {
  useEffect(() => {
    logPageView('404')
  }, [])

  return (
    <Layout title="404">
      <div id="four0four">
        <h2 className="error lg">404</h2>
        <p className="lg">This page doesn't exist</p>
      </div>
    </Layout>
  )
}
